import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Drawer from '../draweContratos';
import CssBaseline from '@material-ui/core/CssBaseline';

import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const style = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        height: 450,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    paper: {

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(1) * 5}px ${theme.spacing(1) * 5}px ${theme.spacing(1) * 5}px`,
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    container: {
        flexGrow: 1,
    },
    botonera: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
    },
    textField: {
        paddingLeft: theme.spacing(1),
        paddingight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2)
    },
    inputLabel: {
        "&.shrink": {
            transform: "translate(24px, -6px) scale(0.75)"
        }
    },

    //
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'no wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },

    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        fontSize: 16,
    },


    divider: {
        height: theme.spacing(2),
    },

    main: {
        // paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(9)
    }

}))


function Page(props) {
    const classes = style();
    const {
        values: { 
            nombre_proyecto, 
            nombre_empresa,
            nombre_responsable,
            correo_responsable,
            correo_responsable_mandante,
        },
        errors,
        touched,
        handleChange,
        isValid,
        handleReset,
        setFieldTouched,
        handleSubmit,
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    return (
        <Fragment>
            <CssBaseline />
            {/* <Drawer></Drawer> */}
            <Container maxWidth="xl" className={classes.main} >
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Link color="inherit" href="/contrato">
                        App ENGIE
                    </Link>
                    <Typography color="textPrimary">Agregar Proyecto</Typography>
                </Breadcrumbs>
                <Card className={classes.card}>
                        <CardContent>
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Grid container spacing={1}>
                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid item xl={3} xs={12} sm={3}>
                                                <TextField
                                                    id="nombre_proyecto"
                                                    name="nombre_proyecto"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.inputLabel,
                                                            shrink: "shrink"
                                                        }
                                                    }}
                                                    error={touched.nombre_proyecto && Boolean(errors.nombre_proyecto)}
                                                    label="Nombre del Proyecto"
                                                    variant="outlined"
                                                    className={clsx(classes.textField, classes.dense)}
                                                    value={nombre_proyecto}
                                                    fullWidth
                                                    onChange={change.bind(null, "nombre_proyecto")}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xl={3} xs={12} sm={3}>
                                                <TextField
                                                    id="nombre_empresa"
                                                    name="nombre_empresa"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.inputLabel,
                                                            shrink: "shrink"
                                                        }
                                                    }}
                                                    error={touched.nombre_empresa && Boolean(errors.nombre_empresa)}
                                                    label="Empresa Contratista "
                                                    variant="outlined"
                                                    className={clsx(classes.textField, classes.dense)}
                                                    value={nombre_empresa}
                                                    fullWidth
                                                    onChange={change.bind(null, "nombre_empresa")}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xl={3} xs={12} sm={3}>
                                                <TextField
                                                    id="nombre_responsable"
                                                    name="nombre_responsable"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.inputLabel,
                                                            shrink: "shrink"
                                                        }
                                                    }}
                                                    error={touched.nombre_responsable && Boolean(errors.nombre_responsable)}
                                                    label="Responsable contratista"
                                                    variant="outlined"
                                                    className={clsx(classes.textField, classes.dense)}
                                                    value={nombre_responsable}
                                                    fullWidth
                                                    onChange={change.bind(null, "nombre_responsable")}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xl={3} xs={12} sm={3}>
                                                <TextField
                                                    id="correo_responsable"
                                                    name="correo_responsable"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.inputLabel,
                                                            shrink: "shrink"
                                                        }
                                                    }}
                                                    error={touched.correo_responsable && Boolean(errors.correo_responsable)}
                                                    label="E-mail Responsable Contratista"
                                                    variant="outlined"
                                                    className={clsx(classes.textField, classes.dense)}
                                                    value={correo_responsable}
                                                    fullWidth
                                                    onChange={change.bind(null, "correo_responsable")}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xl={3} xs={12} sm={3}>
                                                <TextField
                                                    id="correo_responsable_mandante"
                                                    name="correo_responsable_mandante"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.inputLabel,
                                                            shrink: "shrink"
                                                        }
                                                    }}
                                                    error={touched.nombre && Boolean(errors.nombre)}
                                                    label="E-mail Responsable Cliente"
                                                    variant="outlined"
                                                    className={clsx(classes.textField, classes.dense)}
                                                    value={correo_responsable_mandante}
                                                    fullWidth
                                                    onChange={change.bind(null, "correo_responsable_mandante")}
                                                    margin="normal"
                                                />
                                            </Grid>
                                           
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid container spacing={1}>
                                    <div className={classes.botonera}>
                                        <Grid container
                                            direction="row"
                                            justify="flex-end"
                                            alignItems="center"
                                            spacing={1}>
                                            <Grid item >
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={!isValid}>
                                                    Guardar
                                            </Button>
                                            </Grid>
                                            <Grid item >
                                                <Button
                                                    fullWidth
                                                    onClick={handleReset}
                                                    color="primary">
                                                    Limpiar
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
            </Container>
        </Fragment>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};



function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

Menu.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
};

export default Page;
