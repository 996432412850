import React, { Component } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';

import MailIcon from '@material-ui/icons/Mail';
import clickMenuLateral from '../../redux/actions/actionbar/clickMenuLateral';
import closeMenuLateral from '../../redux/actions/menuLateral/closeMenuLateral';

const useStyles = makeStyles({
    list: {
        width: 450,
    },
    fullList: {
        width: 'auto',
    },
});



class DrawerMain extends Component {
    state = {
        left: true,
    };
    constructor(props) {
        super(props);

        this.state = {
            openClose: false,
            click_menu: false
        };
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick(event) {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        const {
            closeMenuLateral,
            //match,
            //history,
        } = this.props;
        closeMenuLateral("CLOSE_MENU_LATERAL");
        return;
    }

    render() {
        //console.log("RE-Remder drawer", this.state.openClose)
        //this.state.openClose  = ! this.state.openClose;

        const sideList = <div className={useStyles.list} role="presentation">
            <List>
                {['hola', 'hola', 'hola hola asdsaasd ', 'hola'].map((text, index) => <ListItem button key={text}>
                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                </ListItem>)}
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => <ListItem button key={text}>
                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                </ListItem>)}
            </List>
        </div>;




        return <div>
            <Drawer open={this.props.click_menu} onClose={this.handleClick}>
                {sideList}
            </Drawer>
        </div>;
    }
}



function mapStateToProps(state) {

    if (state.appBar.accion === 'CLICK_MENU_LATERAL') {
        //  console.log("Execute MapToState");
        return { click_menu: true }
    } else if (state.appBar.accion === 'CLOSE_MENU_LATERAL') {
        //console.log("Execute MapToState");
        return { click_menu: false }
    } else {
        //console.log("Execute MapToState");
        return {

        }
    }

}


const mapDispatchToProps = {
    clickMenuLateral,
    closeMenuLateral
};

// conectar un componente con redux 
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DrawerMain)
);
