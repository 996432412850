import { type as clickMenuLateral } from '../actions/actionbar/clickMenuLateral';
import { type as clickMensajes } from '../actions/actionbar/clickMensajes';
import { type as clickNotificaciones } from '../actions/actionbar/clickNotificaciones';
import { type as closeMenuLateral} from '../actions/menuLateral/closeMenuLateral';


const defaultState = [];

function reducer(state = defaultState, { type, payload,accion }) {

    switch (type) {
        case clickMenuLateral: {
            if (!payload) {
                return [];
            }
            console.log("click en menu lateral ")
            return {payload:payload,accion:accion};
        }
        case clickMensajes: {
            if (!payload) {
                return []
            }
            return {payload:payload,accion:accion};
        }
        case clickNotificaciones: {
            if (!payload) {
                return []
            }
            return {payload:payload,accion:accion};
        }

        case closeMenuLateral:{
            if (!payload) {
                return [];
            }
            console.log("click en menu lateral ")
            return {payload:payload,accion:accion};
        }

        default:
            return state;
    }
}

export default reducer;