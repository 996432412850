export const type = 'showModalGenerico';

const showModalGenerico = (accion,titulo,texto) => ({
    type,
    payload: new Date(),
    accion:accion,
    texto:texto,
    titulo:titulo
});

export default showModalGenerico;