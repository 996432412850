import {type as showModalGenerico} from '../../actions/modal/showModalGenerico';
import {type as hideModalGenerico } from '../../actions/modal/hideModalGenericoResetForm';


const defaultState = [];


function reducer(state = defaultState, { type, payload,accion,titulo,texto }) {
    switch (type) {
        case showModalGenerico: {
            if (!payload) {
                return [];
            }
            return {payload:payload,accion:accion,titulo:titulo,texto:texto};
        } 
        case hideModalGenerico:{
            if(!payload){
                return [];
            }
            return {payload:payload,accion:accion}
        }
        default:
            return state;
    }
}

export default reducer;