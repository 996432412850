import { createStore, combineReducers } from 'redux';

import clickMenuLateral from './reducers/reducerActionBar';
import updateAuthActionBar  from './reducers/reducerUpdateActionBarAuth';


import ModalGenerico from './reducers/modal/reducerModalGenerico';
import ModalLoading from './reducers/modal/reducerModalLoading';


  
  

const reducer = combineReducers({
    appBar:clickMenuLateral,
    appBarAuth:updateAuthActionBar,
    modalGenerico:ModalGenerico,
    modalLoading:ModalLoading
});

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;