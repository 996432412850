import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';


import Work from '@material-ui/icons/Work';
import WorkOutline from '@material-ui/icons/WorkOutline';
import Edit from '@material-ui/icons/Edit';
import NoteAdd from '@material-ui/icons/NoteAdd';

import LocalOffer from '@material-ui/icons/LocalOffer';

import Folder from '@material-ui/icons/Folder';

import FolderShared from '@material-ui/icons/FolderShared';

import AddToQueue from '@material-ui/icons/AddToQueue';

import Typography from '@material-ui/core/Typography';





const drawerWidth = 280;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  menuButton: {
    marginRight: 26,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: 60,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    // width: theme.spacing(0) + 1,
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {

    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));



const menuVerContratoVigentes = ( canPermiso) => {
  // console.log(canPermiso,"permisos ")
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/contrato"  >
        <ListItemIcon >
          <Work color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Contratos Vigentes" />
      </ListItem>
    );
  }
}

const menuVerContratosFinalizados = ( canPermiso) => {
  
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/contrato/terminado"  >
        <ListItemIcon >
          <WorkOutline color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Contratos Terminados" />
      </ListItem>
    );
  }
}
const menuAgregarContrato = ( canPermiso) => {
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/contrato/add"  >
        <ListItemIcon>
          <NoteAdd color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Agregar Contrato" />
      </ListItem>
    );
  }
}
const menuEditarContrato = ( canPermiso) => {
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/contrato/update"  >
        <ListItemIcon>
          <Edit color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Modificar Contrato" />
      </ListItem>
    );
  }
}
const menuVerMandante = ( canPermiso) => {
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/mandante"  >
        <ListItemIcon >
          <LocalOffer color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Mandantes" />
      </ListItem>
    );
  }
}
const menuAgregarMandante = ( canPermiso) => {
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/mandante/add"  >
        <ListItemIcon>
          <NoteAdd color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Agregar Mandante" />
      </ListItem>
    );
  }
}
const menuEditarMandante = ( canPermiso) => {
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/mandante/update"  >
        <ListItemIcon>
          <Edit color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Editar Contrato" />
      </ListItem>
    );
  }
}
const menuVerGerencia = ( canPermiso) => {
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/gerencia"  >
        <ListItemIcon >
          <Folder color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Gerencias" />
      </ListItem>
    );
  }
}
const menuAgregarGerencia = ( canPermiso) => {
  if (canPermiso) {
    return (<ListItem button component={Link} to="/gerencia/add"  >
      <ListItemIcon>
        <NoteAdd color="secondary" />
      </ListItemIcon>
      <ListItemText primary="Agregar Gerencia" />
    </ListItem>);
  }
}
const menuEditarGerencia = ( canPermiso) => {

  if (canPermiso) {
    return (<ListItem button component={Link} to="/gerencia/edit"  >
      <ListItemIcon>
        <Edit color="secondary" />
      </ListItemIcon>
      <ListItemText primary="Editar Gerencia" />
    </ListItem>
    );
  }
}

const menuVerResponsables = (canPermiso) => {
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/gerencia"  >
        <ListItemIcon >
          <FolderShared color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Responsables de Proyecto" />
      </ListItem>

    );
  }
}
const menuAgregarResponsable = (canPermiso) => {
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/gerencia/add"  >
        <ListItemIcon>
          <AddToQueue color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Agregar Persona" />
      </ListItem>
    );
  }
}

const menuEditarResponsable = (canPermiso) => {
  if (canPermiso) {
    return (
      <ListItem button component={Link} to="/gerencia/add"  >
        <ListItemIcon>
          <Edit color="secondary" />
        </ListItemIcon>
        <ListItemText primary="Agregar Persona" />
      </ListItem>
    )
  }
}

// const permisos = {
//   verContrato: true,
//   agregarContrato: false,
//   editarContrato: false,
//   verMandante: false,
//   agregarMandante: false,
//   editarMandante: false,
//   verGerencia: false,
//   agregarGerencia: false,
//   editarGerencia: false,
//   verResponsable: false,
//   agregarResponsable: false,
//   editarResponsable: false,

// }




function Page(props) {
  const {
    openClose,permisos
  } = props;

  // console.log(permisos);
  const classes = useStyles();

  const [open, setOpen] = React.useState(openClose);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <Fragment>
      <div className={classes.paddinTop} onMouseEnter={() => handleDrawerOpen()}
        onMouseLeave={() => handleDrawerClose()}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}>
          <div className={classes.drawerHeader}>
          </div>
          <Divider />
          <List>
            {permisos.verContrato && open && <ListItem>
              <Typography variant="subtitle2" >
                Contratos
                </Typography>
            </ListItem>
            }
            {menuVerContratoVigentes(permisos.verContrato)}
            {menuVerContratosFinalizados(permisos.verContratoTerminados)}
            {menuAgregarContrato(permisos.agregarContrato)}
            {menuEditarContrato(permisos.editarContrato)}
            <Divider />
            {permisos.verMandante && open && <ListItem>
              <Typography variant="subtitle2" >
                Mandantes
                </Typography>
            </ListItem>
            }
            {menuVerMandante(permisos.verMandante)}
            {menuAgregarMandante(permisos.agregarMandante)}
            {/* {menuEditarMandante(permisos.editarMandante)} */}
            <Divider />
            {permisos.verGerencia && open && <ListItem>
              <Typography variant="subtitle2" >
                Gerencia
                </Typography>
            </ListItem>
            }
            {menuVerGerencia(permisos.verGerencia)}
            {menuAgregarGerencia(permisos.agregarGerencia)}
            {/* {menuEditarGerencia(permisos.editarGerencia)} */}
            {permisos.verResponsable && open && <ListItem>
              <Typography variant="subtitle2" >
                Asignacion de Responsables
                </Typography>
            </ListItem>
            }
            {menuVerResponsables(permisos.verResponsable)}
            {menuAgregarResponsable(permisos.agregarResponsable)}
            {menuEditarResponsable(permisos.editarResponsable)}
          </List>

        </Drawer>
      </div>
    </Fragment>
  );
}

export default Page;