import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';


export default function AlertDialog(props) {
    const {
        openDialog,
        handleClose,
        handleCloseAddContrato,
        handleCloseReturnHome,
        titulo,
        texto,
        
        
    } = props

    
    return (
        <div>             
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        {texto}
                    </Typography>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddContrato} color="primary" autoFocus>
                        Agregar otro
                    </Button>
                    <Button onClick={handleCloseReturnHome} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog> 
        </div>
    );
}
