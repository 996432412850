import {type as upDateUserAuth} from '../actions/actionbar/upDateUserAuth';


const defaultState = [];


function reducer(state = defaultState, { type, payload,accion ,avatar_url,user_correo}) {
    
    switch (type) {
        case upDateUserAuth: {
            if (!payload) {
                return [];
            }

            return {payload:payload,accion:accion,avatar_url:avatar_url,user_correo:user_correo};
        }
        
        default:
            return state;
    }
}

export default reducer;