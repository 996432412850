import React, { Component } from 'react';
import Page from './page';



export default class LoadPage extends Component {

    constructor() {
        super();
        this.state = {
            iFrameHeight: '0px'
        }
    }

    render() {
        return (
            <Page/>
        );
    }
}