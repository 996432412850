import {type as showModalLoading} from '../../actions/modal/showModalLoading';
import {type as hideModalLoading } from '../../actions/modal/hideModalLoading';


const defaultState = [];


function reducer(state = defaultState, { type, payload,accion,titulo,texto }) {
    switch (type) {
        case showModalLoading: {
            if (!payload) {
                return [];
            }
            return {payload:payload,accion:accion};
        } 
        case hideModalLoading:{
            if(!payload){
                return [];
            }
            return {payload:payload,accion:accion}
        }
        default:
            return state;
    }
}

export default reducer;