import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import MaterialTable from 'material-table';
import Drawer from '../../draweContratos';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Grid from '@material-ui/core/Grid';

const style = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flex: 1,
        padding: theme.spacing(3),

    },
    contenedor: {
        display: 'flex'
    },
    elemento: {
        width: '25%'
    },
    elemento10: {
        width: '10%'
    },
    padding16: {
        paddingLeft: '16',
        paddingRight: '16'
    },
    elementoContato: {
        width: '45%'
    },
    chip: {
        margin: theme.spacing(1),
    },
    main: {
        
        paddingTop: theme.spacing(9)
    }
    
}))
//


function TablaProyectos(props) {
    const classes = style();
    const {
        data,
        // rol
    } = props;
    
    var tituloTabla = "Listado de proyectos" ;
    return (
        <Fragment>
            <CssBaseline />
            {/* <Drawer ></Drawer> */}

            <main>
                <Container maxWidth="xl" className={classes.main} >

                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Link color="inherit" href="/">
                            Apps ENGINE
                            </Link>
                        {/* <Link color="inherit" href="/getting-started/installation/" >
                            Contratos Vigentes
                            </Link> */}
                        <Typography color="textPrimary">Proyectos </Typography>
                    </Breadcrumbs>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="center" spacing={3}>
                        <Grid item xs={7} xl={6}>
                    <MaterialTable
                        title={tituloTabla}
                        options={{
                            filtering: false,
                            pageSizeOptions: [20, 30, 40, 50, 60],
                            pageSize: 20,
                            grouping: false,
                            actionsColumnIndex: -1
                        }}
                        localization={{
                            toolbar: {
                                searchPlaceholder: "Buscar"
                            },
                            pagination: {
                                labelRowsSelect: "Filas",
                                labelDisplayedRows: '{from}-{to} de {count}'
                            },
                            grouping: {
                                placeholder: " Arrastre los encabezados aquí para agrupar "
                            }

                        }}
                        actions={[
                            // {
                            //     icon: 'edit',
                            //     tooltip: 'Modificar Proyecto',
                                
                            //     onClick: (event, rowData) => {
                            //         console.log("press edit buttom")
                            //         props.onClickContatoParaEditar(rowData);
                            //     }
                            // },
                            // {
                            //     icon: 'delete',
                            //     tooltip: 'Eliminar Proyecto',
                            //     iconProps:{
                                    
                            //     },
                            //     onClick: (event, rowData) => {
                            //         console.log("press edit buttom",rowData)
                            //         props.onClickEliminarProyecto(rowData);
                            //     }
                            // },

                        ]}
                        columns={[
                            {
                                title: '#',
                                field: 'count',
                                cellStyle: {
                                    // fontSize: '0.8em'
                                },

                            },
                            {
                                title: 'Nombre Proyecto',
                                field: 'nombre_proyecto',
                                cellStyle: {
                                    // fontSize: '0.8em'
                                },
                            },
                        ]}
                        data={data}
                    />
                    </Grid></Grid>

                    {/* <iframe src="https://drive.google.com/a/cydingenieria.cl/embeddedfolderview?id=1OO-qmMMK3g7rMkQgOFf2ZXd6RmIs5EfB#grid" style={{height:"100vh",width:"50vw"}}></iframe> */}
                </Container >
            </main>
        </Fragment >
    );
}

export default TablaProyectos;