export const type = 'upDateUserAuth';

const upDateUserAuth = (text,avatar_url,user_correo) => ({
    type,
    payload: new Date(),
    avatar_url:avatar_url,
    user_correo: user_correo,
    accion:text
});

export default upDateUserAuth;