import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { PulseLoader } from 'react-spinners';


const useStyles = makeStyles(theme => ({
    progress: {
      margin: theme.spacing(2),
    },
  }));

export default function AlertDialog(props) {
    const {
        openDialog,
        handleClose,
    } = props    
    
    return (
        <div>             
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"> </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center">
                        <PulseLoader
                            loading={true}
                            color={'#a64c46'}
                        />
                    </Grid>
                    <Typography gutterBottom>
                        {"Procesando"}
                    </Typography>
                </DialogContent>
                
            </Dialog> 
        </div>
    );
}
